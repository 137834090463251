import { Link } from "gatsby"
import React from "react"
import LayoutAlt from "../../components/LayoutAlt"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../../components/seo"

export default function OilandGas() {
  return (
    <LayoutAlt>
      <Seo
        title="Oil and Gas"
        description="CAST Rope Access is a South African  turnkey rope access service
              provider to the Oil and Gas sector."
        pathname="/capabilities/oil-and-gas"
      />
      <section className="hero hero--oil-and-gas px-4 bg-brand-blue flex items-center relative">
        <StaticImage
          className="absolute top-0 right-0 bottom-0 left-0 z-0 h-full w-full object-cover"
          src="../../images/hero-oil-and-gas-v2.jpg"
          alt="Oil and Gas"
          placeholder="blurred"
        />
        <div className="container mx-auto relative z-10">
          <div className="hero__content text-center">
            <h1 className="text-white mx-auto mb-6">Oil &amp; Gas</h1>
            <p className="intro text-white lg:text-lg font-semibold tracking-wider mx-auto mb-8 lg:w-1/2">
              CAST Rope Access is a South African is a global turnkey rope
              access service provider to the Oil and Gas sector.
            </p>
            <Link className="btn btn--oil-and-gas" to="/contact-us">
              Contact Us
            </Link>
          </div>
        </div>
      </section>
      <section className="capability-details px-4 py-24">
        <div className="container mx-auto md:grid md:grid-cols-2 md:gap-8">
          <div className="capability-details__item">
            <div className="grid grid-cols-2 gap-4 mb-6">
              <StaticImage
                className="border-4 border-capability-green shadow-xl rounded-xl"
                src="../../images/oil-and-gas-01-v2.jpg"
                alt="Oil and Gas"
                placeholder="blurred"
              />
              <StaticImage
                className="border-4 border-capability-green shadow-xl rounded-xl"
                src="../../images/oil-and-gas-02.jpg"
                alt="Oil and Gas"
                placeholder="blurred"
              />
            </div>
            <h3 className="text-capability-green mb-6">
              NEAR-SHORE INSPECTIONS &amp; MAINTENANCE
            </h3>
            <ul className="list-disc pl-6 mb-6">
              <li>Surface NDT: UT/MT/EC/PT.</li>
              <li>Pipe Rack Inspection.</li>
              <li>Helideck Inspections.</li>
              <li>NDT Inspections.</li>
              <li>Mooring Chain Inspections.</li>
              <li>Structural Integrity Survey.</li>
              <li>Grit Blasting & Welding.</li>
              <li>Surface repair and prep.</li>
              <li>Steel Renewal.</li>
              <li>Surface repair and prep.</li>
              <li>Painting.</li>
            </ul>
            <span className="mb-6 border-b-2 border-capability-green inline-block h-1 w-16"></span>
          </div>
          <div className="capability-details__item">
            <div className="grid grid-cols-2 gap-4 mb-6">
              <StaticImage
                className="border-4 border-capability-green shadow-xl rounded-xl"
                src="../../images/oil-and-gas-03.jpg"
                alt="Oil and Gas"
                placeholder="blurred"
              />
              <StaticImage
                className="border-4 border-capability-green shadow-xl rounded-xl"
                src="../../images/oil-and-gas-04.jpg"
                alt="Oil and Gas"
                placeholder="blurred"
              />
            </div>
            <h3 className="text-capability-green mb-6">
              FLARE INSPECTION, MAINTENANCE &amp; REPAIRS
            </h3>
            <ul className="list-disc pl-6 mb-6">
              <li>UAV Inspections.</li>
              <li>Advanced NDT Inspections.</li>
              <li>Live Flare Inspection/Maintenance.</li>
              <li>Welding Repair.</li>
              <li>Dropped Objects Survey.</li>
              <li>Steel Repairs & Replacement.</li>
              <li>
                All lifts are planned and assessed prior to commencement and
                detailed risk assessment procedures(RAPS) are followed to ensure
                lifts are completed safely and once only.
              </li>
              <li>Bolt Torquing/Replacement.</li>
            </ul>
            <span className="mb-6 border-b-2 border-capability-green inline-block h-1 w-16"></span>
          </div>
          <div className="capability-details__item">
            <div className="grid grid-cols-2 gap-4 mb-6">
              <StaticImage
                className="border-4 border-capability-green shadow-xl rounded-xl"
                src="../../images/oil-and-gas-05.jpg"
                alt="Oil and Gas"
                placeholder="blurred"
              />
              <StaticImage
                className="border-4 border-capability-green shadow-xl rounded-xl"
                src="../../images/oil-and-gas-06.jpg"
                alt="Oil and Gas"
                placeholder="blurred"
              />
            </div>
            <h3 className="text-capability-green mb-6">
              CHIMNEY INSPECTION &amp; REPAIRS
            </h3>
            <ul className="list-disc pl-6 mb-6">
              <li>UAV External &amp; CSE Inspections.</li>
              <li>Steeple Jacking Services.</li>
              <li>Advanced NDT Inspections.</li>
              <li>Dropped Object Surveys- Surface Prep. &amp; Coating.</li>
              <li>Spalling and Crack Mitigation.</li>
              <li>Cover Meter Tilt &amp; Defamation Surveys.</li>
            </ul>
            <span className="mb-6 border-b-2 border-capability-green inline-block h-1 w-16"></span>
          </div>
          <div className="capability-details__item">
            <div className="grid grid-cols-2 gap-4 mb-6">
              <StaticImage
                className="border-4 border-capability-green shadow-xl rounded-xl"
                src="../../images/oil-and-gas-07.jpg"
                alt="Oil and Gas"
                placeholder="blurred"
              />
              <StaticImage
                className="border-4 border-capability-green shadow-xl rounded-xl"
                src="../../images/oil-and-gas-08.jpg"
                alt="Oil and Gas"
                placeholder="blurred"
              />
            </div>
            <h3 className="text-capability-green mb-6">Storage Tanks</h3>
            <ul className="list-disc pl-6 mb-6">
              <li>API 650 & 653 Inspections.</li>
              <li>Advanced NDT Capabilities.</li>
              <li>API 653 Tank Repairs.</li>
              <li>Dropped Object Surveys.</li>
              <li>Tank Cleaning.</li>
              <li>Surface Preparation.</li>
              <li>Tilt, Deformation &amp; Settlement Surveys.</li>
              <li>Volumetric Surveys.</li>
              <li>Tank Blasting &amp; Painting.</li>
              <li>
                Floating Roof Cleaning &amp; Various Leak Repairs (In &amp; Out
                of Service).
              </li>
            </ul>
            <span className="mb-6 border-b-2 border-capability-green inline-block h-1 w-16"></span>
          </div>
          <div className="capability-details__item">
            <div className="grid grid-cols-2 gap-4 mb-6">
              <StaticImage
                className="border-4 border-capability-green shadow-xl rounded-xl"
                src="../../images/oil-and-gas-09.jpg"
                alt="Oil and Gas"
                placeholder="blurred"
              />
              <StaticImage
                className="border-4 border-capability-green shadow-xl rounded-xl"
                src="../../images/oil-and-gas-10.jpg"
                alt="Oil and Gas"
                placeholder="blurred"
              />
            </div>
            <h3 className="text-capability-green mb-6">
              OFFSHORE PIPELINE INSPECTIONS, REPAIRS &amp; MAINTENANCE
            </h3>
            <ul className="list-disc pl-6 mb-6">
              <li>Close up Visual Inspections.</li>
              <li>Detailed Visual Inspections.</li>
              <li>NDT Inspections.</li>
              <li>Structural Integrity.</li>
              <li>Insulation Repairs &amp; Replacement.</li>
              <li>Bolt Torquing/Replacement.</li>
            </ul>
            <span className="mb-6 border-b-2 border-capability-green inline-block h-1 w-16"></span>
          </div>
          <div className="capability-details__item">
            <div className="grid grid-cols-2 gap-4 mb-6">
              <StaticImage
                className="border-4 border-capability-green shadow-xl rounded-xl"
                src="../../images/oil-and-gas-11.jpg"
                alt="Oil and Gas"
                placeholder="blurred"
              />
              <StaticImage
                className="border-4 border-capability-green shadow-xl rounded-xl"
                src="../../images/oil-and-gas-12.jpg"
                alt="Oil and Gas"
                placeholder="blurred"
              />
            </div>
            <h3 className="text-capability-green mb-6">
              FPSO INSPECTIONS, REPAIRS &amp; MAINTENANCE
            </h3>
            <ul className="list-disc pl-6 mb-6">
              <li>Surface NDT: UT/MT/EC/PT.</li>
              <li>Pipe Rack Inspection.</li>
              <li>Helideck Inspections.</li>
              <li>NDT Inspections.</li>
              <li>Mooring Chain Inspections.</li>
              <li>Structural Integrity Survey.</li>
              <li>Grit Blasting & Welding.</li>
              <li>Surface repair and prep.</li>
              <li>Steel Renewal.</li>
              <li>Surface repair and prep.</li>
              <li>Painting.</li>
              <li>Flare Structure Inspections &amp; Maintenance.</li>
            </ul>
            <span className="mb-6 border-b-2 border-capability-green inline-block h-1 w-16"></span>
          </div>
          <div className="capability-details__item">
            <div className="grid grid-cols-2 gap-4 mb-6">
              <StaticImage
                className="border-4 border-capability-green shadow-xl rounded-xl"
                src="../../images/oil-and-gas-13.jpg"
                alt="Oil and Gas"
                placeholder="blurred"
              />
              <StaticImage
                className="border-4 border-capability-green shadow-xl rounded-xl"
                src="../../images/oil-and-gas-14.jpg"
                alt="Oil and Gas"
                placeholder="blurred"
              />
            </div>
            <h3 className="text-capability-green mb-6">
              Offshore Installation
            </h3>
            <ul className="list-disc pl-6 mb-6">
              <li>NDT: UT/MT/EC/PT.</li>
              <li>Pipe Rack Inspection.</li>
              <li>Helideck Inspections.</li>
              <li>NDT Inspections.</li>
              <li>Structural Integrity Survey.</li>
              <li>Surface preparation &amp; Coating.</li>
              <li>Welding.</li>
              <li>Steel Renewal.</li>
              <li>Surface repair and prep.</li>
              <li>Painting.</li>
              <li>Flare Structure Inspections& Maintenance.</li>
              <li>Dropped Object Survey.</li>
            </ul>
            <span className="mb-6 border-b-2 border-capability-green inline-block h-1 w-16"></span>
          </div>

          <div className="capability-details__item">
            <div className="grid grid-cols-2 gap-4 mb-6">
              <StaticImage
                className="border-4 border-capability-green shadow-xl rounded-xl"
                src="../../images/renewables-03.jpg"
                alt="Renewables"
                placeholder="blurred"
              />
              <StaticImage
                className="border-4 border-capability-green shadow-xl rounded-xl"
                src="../../images/renewables-04.jpg"
                alt="Renewables"
                placeholder="blurred"
              />
            </div>
            <h3 className="text-capability-green mb-6">
              JACK UP REPAIRS &amp; MAINTENANCE
            </h3>
            <ul className="list-disc pl-6 mb-6">
              <li>Surface NDT: UT/MT/EC/PT.</li>
              <li>Structural Integrity Survey.</li>
              <li>Dropped Object Surveys.</li>
              <li>Surface preparation &amp; Coating.</li>
              <li>Welding.</li>
              <li>Steel Renewal.</li>
              <li>Surface repair &amp; prep.</li>
              <li>Bolt Surveys, Torquing &amp; Replacement.</li>
            </ul>
            <span className="mb-6 border-b-2 border-capability-green inline-block h-1 w-16"></span>
          </div>
        </div>
        <div className="text-center">
          <Link className="btn btn--oil-and-gas" to="/contact-us">
            Contact Us
          </Link>
        </div>
      </section>
    </LayoutAlt>
  )
}
